<template>
  <div>
    <div class="row my-3">
      <h1 class="col-sm-12 text-right">Configuraciones</h1>
    </div>

    <DataGrid :configuracion="datagrid_config" :data="configuraciones" @ready="datagrid_btns">
      <button class="btn btn-warning mr-2" @click="obtener_configuraciones">Recargar</button>
      <button class="btn btn-primary mr-2" @click="ver_modal=true">Agregar configuración</button>
    </DataGrid>

    <Editar v-if="ver_modal" :conf="tmp_conf" @close="ver_modal=false" @update="actualizar_configuraciones" />
  </div>
</template>

<script>
import DataGrid from '@/components/DataGridV3';

import Editar from './Configuracion/Editar'

import api from '@/apps/fyc/api/configuracion';

export default {
  components: {
    DataGrid, Editar
  },
  data() {
    return {
      datagrid_config: {
        name: 'configuraciones',
        selector: false,
        cols: {
          id: 'ID',
          nombre: 'Nombre',
          valor: 'Valor',
          tipo: 'Tipo',
          descripcion: 'Descripción',
          valor_seguro: 'Encriptado',
          created_at: 'Fecha',
          otro: ''
        },
        mutators: {
          nombre(val) {
            let mayor_a = 30;
            return (val.length > mayor_a ? val.substr(0,mayor_a)+'...' : val);
          },
          valor(val, row) {
            let mayor_a = 30;
            return (val.length > mayor_a ? val.substr(0,mayor_a)+'...' : val);
          },
          created_at(val,row,vue) {
            return vue.$moment(val).format('YYYY-MM-DD hh:mm');
          },
          otro(va,row) {
            return '<button class="btn btn-primary edit-btn" data-id="'+row.id+'">Editar</button>';
          }
        }
      },
      configuraciones:[],
      ver_modal: false,
      tmp_conf: null
    }
  },
  mounted() {
    this.obtener_configuraciones();
  },
  methods: {
    async obtener_configuraciones() {
      try {
        this.configuraciones = (await api.obtener_configuraciones()).data;
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    datagrid_btns() {
      document.querySelectorAll('.edit-btn').forEach(btn => {
        btn.removeEventListener('click', this.acciones_btns);
        btn.addEventListener('click', this.acciones_btns);
      })
    },
    acciones_btns(e) {
      let id = e.target.dataset.id;
      let conf = null;

      this.configuraciones.forEach(c => {
        if (c.id == id) {
          conf = c;
        }
      });

      if (conf) {
        this.tmp_conf = conf;
        this.ver_modal = true;
      }
    },
    actualizar_configuraciones() {
      this.obtener_configuraciones();
      this.ver_modal=false;
    }
  }
}
</script>